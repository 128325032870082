
import LayoutFooter from '~/components/layout/LayoutFooter.vue'
import LayoutHeader from '~/components/layout/LayoutHeader.vue'
import MainPageContent from '~/components/new/main-page/MainPageContent.vue'
import PaintingsBlock from '~/components/new/main-page/PaintingsBlock.vue'
import { store } from '~/store/modals'

export default {
  components: { LayoutHeader, LayoutFooter, PaintingsBlock, MainPageContent },
  layout: 'empty',
  data () {
    return {
      galleryStyles: true
    }
  },
  head () {
    return {
      title: 'Arthall online',
      meta: [
        // { hid: 'description', name: 'description', content: this.$t('head.description.index') },
        // { hid: 'og:title', name: 'og:title', content: this.$t('head.title.index') },
        // { hid: 'og:description', name: 'og:description', content: this.$t('head.description.index') },
        // { hid: 'og:image', name: 'og:image', content: require('~/assets/img/logo-black.svg') },
        // { hid: 'og:type', name: 'og:type', content: 'website' },
        // { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  },
  mounted () {
    // @ts-ignore
    const paintingsEl: HTMLElement | undefined = this.$refs.paintings?.$vnode.elm
    // @ts-ignore
    const mainPageEl: HTMLElement = this.$refs.mainPage as HTMLElement
    if (!paintingsEl || !mainPageEl) { return }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) { store.value.addModal() } else { store.value.removeModal() }
      })
    }, { threshold: 1 })

    observer.observe(paintingsEl)
    window.scroll({ top: -window.scrollY })
  },
  unMounted () {
    // @ts-ignore

  },
  created () {
  },
  methods: {
    toGalleryLinkHandler () {
      // @ts-ignore

      (this.$refs.paintings?.$el as HTMLElement).scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    toPageLinkHandler () {
      // @ts-ignore
      (this.$refs.page as HTMLElement).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
