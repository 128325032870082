import { render, staticRenderFns } from "./PaintingsBlock.vue?vue&type=template&id=a9afd594&scoped=true&"
import script from "./PaintingsBlock.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PaintingsBlock.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PaintingsBlock.vue?vue&type=style&index=0&id=a9afd594&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9afd594",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutMenu: require('/var/www/arthall/arthall.front/components/layout/LayoutMenu.vue').default})
